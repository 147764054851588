<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
        </div>
        <el-card class="el-main">
            <div class="a-flex-rsbc">
                <div class="a-flex-rfsfe">
                    <span class="a-c-master a-fw-700 a-fs-16">电费站点趋势</span>
                </div>
                <le-date-range 
                    style="padding: 0 !important"
                    @change="dateChange"
                    ref="dateRange"
                    :pickerOptions="pickerOptions" 
                    :minDate.sync="startTime" 
                    :maxDate.sync="endTime" 
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
            </div>
            <le-echarts-line :echartsObj="echartsObj" ></le-echarts-line>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="cancelSubmit">关闭</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data () {
        return {
            id: '',
            startTime: '',
            endTime: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    if (maxDate) {
                        this.choiceDate = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        let one = 31 * 24 * 3600 * 1000
                        const minTime = this.choiceDate - one
                        const maxTime = this.choiceDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }else{
                        return ''
                    }
                },
            },
            echartsObj: {
                id: 'electric',
                height: 'calc(100vh - 100px)',
                xAxisData: [],
                seriesData0: [],
                seriesData1: [],
                seriesData2: [],
                seriesName0: '设备插槽数',
                seriesName1: '电费缴费',
                seriesName2: '电费比',
                seriesUnit0: '',
                seriesUnit1: '元',
                seriesUnit2: '%',
                yAxisIndex0: 0,
                yAxisIndex1: 1,
                yAxisIndex2: 2,
            },
        }
    },
    mounted () {
        this.id = this.$route.query.id
        if(this.id) {
            this.getInfo()
        }
    },
    methods:{
        dateChange () {
            this.getInfo()
        },
        getInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.electricBillChart,
                params: {
                    projectId: this.id,
                    startDate: this.startTime,
                    endDate: this.endTime
                },
            }).then((res) => {
                if (res.result.code == 0) {
                    this.echartsObj.xAxisData = []
                    this.echartsObj.seriesData0 = []
                    this.echartsObj.seriesData1 = []
                    this.echartsObj.seriesData2 = []
                    res.result.data.map((item,index)=>{
                        this.echartsObj.xAxisData.push(item.recordDate)
                        this.echartsObj.seriesData0.push(item.slotNum)
                        this.echartsObj.seriesData1.push((item.totalPay / 100).toFixed(2))
                        this.echartsObj.seriesData2.push((item.ratio / 100).toFixed(2))
                    })
                }
            });
        },
        cancelSubmit () {
            this.$router.back()
            this.$store.dispatch("delView", this.$route);
        }
    },
}
</script>

<style lang="scss" scoped>
.send-user{
    background: #53A8FF;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 10px 10px 0;
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 12px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 150px;
    margin-right: 16px;
}
.content{
    width: 240px
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
</style>